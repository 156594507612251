/**
 * Toast
 * @requires https://getbootstrap.com
 */

export default (() => {
  const toastElList = [].slice.call(document.querySelectorAll('.toast'))

  const toastList = toastElList.map((toastEl) => new bootstrap.Toast(toastEl))
})()
