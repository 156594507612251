/**
 * Popover
 * @requires https://getbootstrap.com
 * @requires https://popper.js.org/
 */

export default (() => {
  const popoverTriggerList = document.querySelectorAll(
    '[data-bs-toggle="popover"]'
  )

  const popoverList = [...popoverTriggerList].map(
    (popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl)
  )
})()
